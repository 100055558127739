import { $_PGW } from './pgw.mjs';
var $_CONFIG = $_CONFIG || {};

$_CONFIG.apiVersion = 'v2';
$_CONFIG.swLogger = false;
$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportId = 'wazamba';
$_CONFIG.sportWidgetSkinName = 'wazamba';
$_CONFIG.useSvgSprite = true;

$_CONFIG.currentType = 'casino'; //- casino || sport
$_CONFIG.pgw = $_PGW;

$_CONFIG.domain = 'https://wazamba.devv2.space'; //- домен апі (рекомендовано залишати пустим)
$_CONFIG.siteDomain = 'https://wazamba.com';
$_CONFIG.siteName = 'wazamba';
$_CONFIG.mobile = false;
if (typeof window !== 'undefined') {
  $_CONFIG.feature = { ...window?.$_CDN?.feature || {}, league_tournament: {visible: true} };
}

$_CONFIG.cdn = '/joxi';                           //- домен сервера cdn (поки один на всі проекти)
//- $_CONFIG.gsapVersion = '3';
$_CONFIG.avatars = {
  0: {name: 'advar'},
  1: {name: 'bomani'},
  2: {name: 'chimola'},
};

$_CONFIG.googleAnalytics = {appId: 'UA-131568742-1', trackAs: 'wazamba'};

$_CONFIG.swiper = true;

$_CONFIG.libphonenumber = true;

$_CONFIG.redirectToNotFound = false;

$_CONFIG.mid = null; //- id пікселя для інкам. ВАЖЛИВО! В кожного сайта своє

$_CONFIG.symplify = true;
//- ПЛЕЙСИ ДЛЯ БАНЕРІВ І СЛАЙДЕРІВ
//- в шаблоні вказувати саме об'єкт конфіга.
$_CONFIG.places = {
  banner: {
    login: `'login_banner'`,
    registration: `'registration_banner'`,
    home1: `'sport_main_banner'`,
    home2: `'paynplay_pay'`,
    home3: `'sport_promotion'`,
    home4: `'live_promotion'`,
    home5: `'casino_promotion'`,
    home6: `'collection_main_banner'`,
    entrance_bnr1: `'entrance_1'`,
    entrance_bnr2: `'gamehall'`,
    entrance_bnr3_1: `'entrance_3_1'`,
    entrance_bnr3_2: `'entrance_3_2'`,
    entrance_bnr3_3: `'entrance_3_3'`,
    entrance_tournament: `'entrance_tournament'`,
    inframe_banner1_1: `'inframe_banner1'`,
    inframe_banner1_2: `'inframe_banner2'`,
    inframe_banner1_3: `'inframe_banner3'`,
    entrance_bnr3: `'entrance_3'`,
    entrance_bnr4: `'entrance_4'`,
    entrance_bnr5: `'entrance_5'`,
    gamehall_challenges: `'gamehall_challenges'`,
  },
  slider: {
    gamehall1: `'gamehall'`, //-for video banner in game hall page
    gamehall_slider: `'gamehall_slider'`,

  },
  promo: {
    registration: `'welcome_bonus'`,
    home1: `'sport_main'`,
    home2: `'casino_main'`,
    home3: `'g.casino'`,
    card_guide: `'collection_guide'`
  },
  icons: {
    footer1: `'footer_pay'`,
    paynplay: `'paynplay_pay'`
  }
};

$_CONFIG.banners = {
  bottomRight: {
    url: 'https://joxi.imgsrcdata.com/rabona/sports-banner-frame/{lang}/right-banner1/{lang}1.jpg',
    link: 'https://rabona.com/{lang}/champions-cup'
  }
};

$_CONFIG.sportBanners = {
  topCenter: {
    iframe: `/sport-banners-slider/`,
    options: {
      pages: ['prelive'],
      height: '190px'
    }
  },
  aboveHighlights: {
    iframe: '/sport-banners-slider/live-stream/',
    options: {
      pages: ['prelive'],
      height: '250px'
    }
  },
  bottomLeft: {
    iframe: '/sport-banners-slider/sport-leaderboard/',
    options: {
      pages: ['prelive'],
      height: '168px'
    }
  },
  bottomRight: {
    iframe: `${$_CONFIG.cdn}/js/crab/sdk/views/widget-sport.html?build=${Date.now()}`,
    options: {
      height: '486px',
    }
  }

};

$_CONFIG.bonusSuccessAlias = {
  exchange: 'casinoTop',
  casino: 'casinoTop',
  freebet: 'sport',
  sport: 'sport',
  freespin: 'realGame',
};

$_CONFIG.scss = {
  //- об'єкт, який передається в scss при компіляції
  test: 'test',
};

$_CONFIG.places = {
  banner: {
    registration: `'registration_welcome_banner'`,
  },
};

if (typeof window !== 'undefined') {
  window.$_REGISTRATION = {};
  window.$_REGISTRATION.promotions = {
    category: 'welcome_bonus',
    active: 0,
  };
  window.$_REGISTRATION.avatars = {
    active: 1,
  };
}

export { $_CONFIG };
