const router = [
  //empty
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk show-league-popup-ws></linda-app>',
  },
  {
    name: 'app.root.summer',
    url: '/seasons/summer',
    private: true,
    template: '<summer-promo-wrapper scroll-to-top="" ></summer-promo-wrapper>',
  },
  {
    name: 'app.root.summer-game',
    url: '/seasons-summer',
    private: true,
    template: `<summer-promo-page scroll-to-top=""></summer-promo-page>`,
  },

  {
    name: 'app.root.layer1',
    abstract: true,
    template: '<linda-view-layer-one></linda-view-layer-one>',
  },

  {
    name: 'app.root.layer1.layer2',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>',
  },

  {
    name: 'app.root.layer1.layer2.registration',
    url: '/registration',
    template: '<linda-registration-page redirect="\'app.root.layer1.home\'"></linda-registration-page>',
  },

  {
    name: 'app.root.paymentcb2',
    url: '/payment/status?status',
    template: '<linda-payment-callback ></linda-payment-callback>',
  },

  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback ></linda-payment-callback>',
  },

  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/success',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'success',
    },
  },
  {
    name: 'app.root.withdraw-paymentcb-fail',
    url: '/withdraw-payment/fail',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'fail',
    },
  },
  {
    name: 'app.root.layer1.registration-bank',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page></linda-zimpler-paynplay-page>',
  },

  {
    name: 'app.root.layer1.shop',
    abstract: true,
    template: '<linda-shop-page scroll-to-top=""/>',
  },
  {
    name: 'app.root.layer1.shop.main',
    url: '/shop',
    template: '<linda-shop-public-wrapper scroll-to-top="" />',
  },
  {
    name: 'app.root.layer1.shop.tab',
    url: '/shop/:tab',
    template: '<linda-shop-public-wrapper scroll-to-top="" />',
  },

  //GAME PAGE

  // <MACHINE>
  {
    name: 'app.root.claw_games',
    abstract: true,
    private: true,
    template: '<linda-game-page />'
  },
  {
    name: 'app.root.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-ui-wrapper5 />',
    params: {
      page: 'crab',
    },
  },
  /*
      {
        name: 'app.root.cash-crab',
        url: '/play/cash-crab',
        template: '<batman-view-layer1 />',
        private: true,
      },
      {
        name: 'app.root.cash_crab',
        url: '/play/cash_crab',
        template: '<batman-view-layer2 />',
        private: true,
      },
  */

  // </MACHINE>

  {
    name: 'app.root.game',
    abstract: true,
    template: '<linda-game-page />',
    gamePage: true
  },

  {
    name: 'app.root.game.real',
    url: '/play/:name',
    private: true,
    params: {
        mode: 'real'
    }
  },
  {
    name: 'app.root.game.demo',
    url: '/game/:name',
    params: {
        mode: 'demo'
    }
  },

  {
    name: 'app.root.layer1.layer2.cookies-policy',
    url: '/cookie-notice',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
    },
  },

  {
    name: 'app.root.layer1.league',
    url: '/vip',
    template: '<linda-vip open-league-faq add-class-to-scroll></linda-vip>',
  },
  {
    name: 'app.root.layer1.leagueresult',
    private: true,
    url: '/league-result',
    template: '<batman-view-layer4 scroll-to-top="" open-league-faq add-class-to-scroll></batman-view-layer4>',
  },
  {
    name: 'app.root.layer1.leaguefaq',
    url: '/league-faq',
    template: '<batman-view-layer1 scroll-to-top="" class="league-faq" add-class-to-scroll></batman-view-layer1>',
  },

  //HOME
  {
    name: 'app.root.layer1.home',
    url: '/',
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },
  {
    name: 'app.root.missed-data',
    url: '/missed-data',
    template: '<linda-missed-data-form></linda-missed-data-form>',
  },
  {
    name: 'app.root.layer1.layer2.promos',
    abstract: true,
    template: '<batman-view-layer5 scroll-to-top="" ></batman-view-layer5>',
  },
  {
    name: 'app.root.layer1.layer2.promos.root',
    url: '/promotions',
    template: '<batman-promo-list3 scroll-to-top="" ></batman-promo-list3>',
    params: {
      filter: 'casino',
    },
  },
  {
    name: 'app.root.layer1.layer2.promos.list',
    url: '/promotions/:filter',
    template: '<batman-promo-list3 scroll-to-top="" ></batman-promo-list3>',
    params: {
      filter: 'casino',
    },
  },
  {
    name: 'app.root.layer1.layer2.promopage',
    url: '/promotions/:filter/:name',
    template: '<linda-promo-page scroll-to-top="" ></linda-promo-page>',
  },
  {
    name: 'app.root.layer1.layer2.promopage.santa_slope',
    url: '/promotions/:filter/santa_slope',
    template: '<linda-promo-page scroll-to-top="" ></linda-promo-page>',
    params: {
      filter: 'casino',
    },
  },
  {
    name: 'app.root.layer1.layer2.catalog',
    abstract: true,
    template: '<linda-casino-section ></linda-casino-section>',
  },

  //providers
  {
    name: 'app.root.layer1.layer2.catalog.provider',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.layer1.layer2.catalog.provider.list',
    url: '/casino-providers',
    template: '<linda-provider-list></linda-provider-list>',
  },
  {
    name: 'app.root.layer1.layer2.catalog.provider.hall',
    url: '/casino-providers/:name',
    template: '<linda-game-hall-providers></linda-game-hall-providers>',
  },
  {
    name: 'app.root.layer1.layer2.catalog.favourites',
    url: '/games-favourites',
    template: '<batman-game-box-favourites2></batman-game-box-favourites2>',
  },

  {
    name: 'app.root.layer1.layer2.catalog.category',
    url: '/games/:name',
    template: '<linda-game-hall scroll-to-top=""></linda-game-hall>',
  },

  // PROFILE

  {
    name: 'app.root.layer1.layer2.account',
    abstract: true,
    private: true,
    template: '<linda-profile-page ></linda-profile-page>',
  },
  {
    name: 'app.root.layer1.layer2.account.heroroom',
    url: '/account/hero-room',
    template: '<linda-profile-staff scroll-to-top="" ></linda-profile-staff>',
  },
  {
    name: 'app.root.layer1.layer2.account.bonus',
    url: '/account/bonuses',
    template: '<linda-profile-bonus-list scroll-to-top=""></linda-profile-bonus-list>',
  },
  {
    name: 'app.root.layer1.layer2.account.history',
    url: '/account/history',
    template: '<linda-cashbox-history-casino scroll-to-top=""></linda-cashbox-history-casino>',
  },
  {
    name: 'app.root.layer1.layer2.account.settings',
    url: '/account/settings',
    template: '<linda-profile-form scroll-to-top=""></linda-profile-form>',
  },
  {
    name: 'app.root.layer1.layer2.account.verification',
    url: '/account/verification',
    template: '<dvs-page scroll-to-top></dvs-page>',
  },

  // TOURNAMENTS

    {
        name: 'app.root.layer1.layer2.tournament',
        abstract: true,
        template: '<ui-view></ui-view>',
    },
    {
        name: 'app.root.layer1.layer2.tournament.list',
        url: '/tournaments',
        template: '<batman-tournament-list3 players="true" competitors="5" scroll-to-top="" ></batman-tournament-list3>',
  },
  {
    name: 'app.root.layer1.layer2.tournament.page',
    url: '/tournaments/:name',
    template: '<linda-tournament-page scroll-to-top=""></linda-tournament-page>',
  },
  {
    name: 'app.root.layer1.layer2.sporttournament',
    url: '/sport-tournament',
    template: '<linda-view-layer-eight scroll-to-top="" ></linda-view-layer-eight>',
  },

  //INFO PAGES

  {
    name: 'app.root.layer1.layer2.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap scroll-to-top=""></linda-sitemap>',
  },
  {
    name: 'app.root.layer1.layer2.privacy-policy',
    url: '/privacy-notice',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.about',
    url: '/about',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.layer1.layer2.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.layer1.layer2.rules',
    url: '/rules',
    template: '<linda-info-page ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
      selector: 'false',
    }
  },
  {
    name: 'app.root.layer1.layer2.rules-anexcrab',
    url: '/rules-crab',
    template: '<linda-info-page ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
      selector: '#anex-crab',
    }
  },
  {
    name: 'app.root.layer1.layer2.rules-anexshop',
    url: '/rules-shop',
    template: '<linda-info-page ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
      selector: '#anex-shop',
    }
  },
  {
    name: 'app.root.layer1.layer2.faq',
    url: '/faq',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },
  {
    name: 'app.root.layer1.layer2.contacts',
    url: '/contact-us',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },
  {
    name: 'app.root.layer1.layer2.achievements',
    url: '/achievements',
    template: '<linda-achievements-page scroll-to-top="" ></linda-achievements-page>',
  },
  //- mask-collection
  {
    name: 'app.root.layer1.layer2.mask-collection',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.layer1.layer2.mask-collection.list',
    url: '/mask-collections',
    template: '<linda-mask-collections-page scroll-to-top="" ></linda-mask-collections-page>',
  },
  {
    name: 'app.root.layer1.layer2.mask-collection.page',
    url: '/mask-collections/:name',
    template: '<linda-mask-collection-page scroll-to-top=""></linda-mask-collection-page>',
    private: true,
  },

  // SPORT

  {
    name: 'app.root.layer1.sportpage',
    abstract: true,
    template: '<linda-sport-page wazamba-hide-sidebar ></linda-sport-page>',
    params: {
      sport: true,
    },
  },

  {
    name: 'app.root.layer1.horse_racing',
    abstract: true,
    template: '<linda-view-layer-seven scroll-to-top></linda-view-layer-seven>',
  },

  {
    name: 'app.root.layer1.horse_racing.horse_racing',
    url: '/horse-racing',
    params: {
      category: 'horseRacing',
    },
  },

  {
    name: 'app.root.layer1.horse_racing.harness',
    url: '/harness',
    params: {
      category: 'harness',
    },
  },

  {
    name: 'app.root.layer1.horse_racing.greyhound',
    url: '/greyhound',
    params: {
      category: 'greyhound',
    },
  },

  {
    name: 'app.root.layer1.horse_racing.next-to-jump',
    url: '/next-to-jump',
    params: {
      category: 'nextToJump',
    },
  },

  {
    name: 'app.root.layer1.sportpage.prelive',
    url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'prelive',
    },
  },
  {
    name: 'app.root.layer1.sportpage.live',
    url: '/sport/live?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'live',
    },
  },
  {
    name: 'app.root.layer1.sportpage.virtual',
    url: '/sport/virtual?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'vflm',
    },
  },

  {
    name: 'app.root.layer1.layer2.not-found',
    url: '/404',
    template: '<not-found></not-found>',
  },

  /**
   * All payments page
   * */
  {
    name: 'app.root.layer1.layer2.payments-list',
    url: '/payments-page',
    template: '<batman-banking-page1 scroll-to-top=""></batman-banking-page1>',
  },

  {
    name: 'app.root.layer1.layer2.catalog.live',
    url: '/live-casino/:name',
    template: '<batman-game-hall1 scroll-to-top=""></batman-game-hall1>',
    params: {
      collectionAlias: 'live-casino-categories',
    },
  },

  // --- ROUTE FOR SEASON PROMOS ---
  // {
  //   name: 'app.root.layer1.layer2.seasons',
  //   url: '/seasons/:name',
  //   template: `<linda-promo-wrapper wazamba-hide-sidebar scroll-to-top="" ></linda-promo-wrapper>`
  // },
];
export { router };
