import { provideIntoWindow } from "@b2c/core/app/default/provide-into-window.js";
import { brandConfig } from "../index.js";
import { $_ALTENARCONFIG } from "./../altenar.mjs";

const pgwUrl = 'https://app.payment-gateway.io';

const productionConfig = {
    pgwConfig: {
        pgwUrl: pgwUrl,
        scriptUrl: pgwUrl + '/default/index.js',
        pgwPaymentLightSvg: pgwUrl + '/assets/logos/light/large/',
        pgwPaymentDarkSvg: pgwUrl + '/assets/logos/dark/large/',
        merchantKey: 'dfe120cf-8571-452e-bde0-0e5fd63f61ff'
    }
};

provideIntoWindow("$_ALTENARCONFIG", $_ALTENARCONFIG);

export const prod = provideIntoWindow(
    "$_CONFIG",
    Object.assign({}, brandConfig, productionConfig)
);

